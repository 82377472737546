import * as React from 'react'
import * as S from './Footer.styles'

export const Footer: React.FC = (props) => {
  return (
    <S.Container {...props}>
      <S.BrandInitial>M</S.BrandInitial>ariuzzo
    </S.Container>
  )
}
